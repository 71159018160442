import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import AnimatedHr from '../components/animatedHr'

const AboutUs = () => (
  <Layout>
    <div className="headerImage catering">
      <div className="headerImageContent">
        <div className="headerImageText">
          <div>
            <h1>Catering Repairs</h1>
            <div style={{ display: 'inline-block' }}>
              <AnimatedHr />
            </div>
            <p>
              Nova Electronics for repairs to Catering equipment in Sheffield
            </p>
          </div>
        </div>
      </div>
    </div>

    <section>
      <div className="container">
        <div className="row">
          <div className="six columns">
            <h2>Nova Electronics in Sheffield for catering repairs</h2>
            <p>
              If your catering equipment has a fault or needs repairing, Nova
              Electronics will be right at your side to help. We know your
              business is at risk and you can’t afford to wait.
            </p>
            <p>
              Nova Electronics is truly your local support – we are the only
              catering repairs business in the centre of Sheffield. We work
              closely with many kinds of takeaways and restaurants, so we know
              what you are up against when something breaks.
            </p>

            <p>
              You can bring equipment to our premises where we will get it fixed
              fast. We deliberately carry many spares, covering all
              manufacturers, to give you fast repair times. You want to get back
              to normal trading quickly – so do we.
            </p>
            <h2>
              We repair many types of catering equipment – and the circuit
              boards in them.
            </h2>

            <p>
              Our specialist engineers can fix problems across many types of
              kitchen equipment. Our most common repairs are on:
            </p>
            <ul>
              <li>Commercial Microwaves and Combination ovens</li>
              <li>Deep Fat Fryers</li>
              <li>Dishwashers</li>
              <li>Ice makers</li>
              <li>Kebab equipment</li>
              <li>Ovens and grills</li>
              <li>Hot Cupboards</li>
              <li>Servery Counters</li>
              <li>Mixers and Blenders</li>
            </ul>
            <p>
              In most catering equipment these days there will Printed Circuit
              Boards (PCBs) and if these fail, they can be costly to replace.
              Nova Electronics gives you the repair option. This means much
              lower cost and your faithful equipment back in action quickly.
            </p>
            <p>
              As always with Nova Electronics, we will look at any catering
              equipment problem. We will do what we can to fix it – quickly and
              keeping your costs down. If we can’t fix it at a sensible price,
              we will tell you and give you advice.
            </p>
          </div>
          <div className="six columns">
            <div className="serviceBenefits">
              <h3>Nova's principles for pricing</h3>
              <div
                style={{
                  padding: '20px',
                }}
              >
                <p>
                  Nova Electronics have three principles about keeping your
                  repair costs low
                </p>

                <ol>
                  <li>
                    We talk with you about the problem and the likely costs. We
                    won’t hide anything. We are known for our honesty
                  </li>
                  <li>
                    We only do what is necessary to fix the problem – we don’t
                    do “gold-plating”
                  </li>
                  <li>
                    If something else is found or is needed – we will call you
                    first to discuss it.
                  </li>
                </ol>

                <Link to="/contact-us" className="button">
                  Book in your Repair
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default AboutUs
